import { IonSearchbar, IonButton } from "@ionic/react";
import styled from "styled-components";
import { IonCol } from '@ionic/react';

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const Searchbar = styled(IonSearchbar)`
  margin-bottom: auto;
`;

export const BorderedIonCol = styled(IonCol)`
  border: 0.5px solid var(--ion-color-light-tint);
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 5px;
`;

export const SubmitButton = styled(IonButton)`
  max-width: 500px;
  flex-grow: 1;
`;